import React from 'react'

const Footer = ({ setShowModal }) => {
    return (
        <footer className=" pt-8 ">
            <div className="  row justify-between items-center">
                <div className='w-full flex justify-around'>
                    <div className="flex justify-around w-5/6 mb-6">
                        {/* <img src="images/cronn_green_black-white.svg" alt="Cronn Logo" className="md:h-12 h-6" /> */}
                        <div className='table-row'>
                            <h3 style={{fontSize:"0.55rem", lineHeight:"20px"}} className='flex md:hidden text-decoration-line: underline'>Kooperation:</h3>
                            <h3 style={{lineHeight:"30px"}} className='hidden md:flex text-decoration-line: underline font-bold'>Kooperation:</h3>
                            <img src="images/ga-ffm_logo.svg" alt="GA-FFM Logo" className="md:h-12 h-6" />
                        </div>
                        {/* <div className='flex md:hidden text-center mt-6'><p className='footerFontS'>Hessisches Ministerium für Familie,<br />Senioren, Sport, Gesundheit und Pflege</p></div>
                        <div className='hidden md:flex xl:hidden text-center mt-10'><p className='footerFontM'>Hessisches Ministerium für Familie,<br />Senioren, Sport, Gesundheit und Pflege</p></div>
                        <div className='hidden xl:flex text-center mt-8'><p className='footerFontB'>Hessisches Ministerium für Familie,<br />Senioren, Sport, Gesundheit und Pflege</p></div> */}
                        <div>
                            <img src="images/RGB_Logo.png" alt="HMFF Logo" className="md:h-16 h-6 md:mt-2 mt-4" />
                        </div>
                        <div>
                            <img src="images/funded_by_eu.svg" alt="Funded by EU Logo" className="md:h-12 h-6 mt-6 md:mt-6" />
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor: "#CBD9E7" }} className="h-20 pt-8 w-full  ">
                    <div className=' '>
                        <div className='flex justify-end'>
                            <button onClick={() => setShowModal(true)} className="text-primary-plain-Color hover:underline pr-4">Impressum</button>
                            <a href="https://frankfurt.de/datenschutz" target="_blank" rel="noopener noreferrer" className="text-primary-plain-Color hover:underline pr-8">Datenschutz</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer