import React, { useEffect, useState, useRef } from 'react';
import { XCircle } from 'lucide-react';
import Footer from './Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useOutsideClick } from '../hooks/useOutsideClick';



// const FontLoader = () => (
//   <style jsx global>{`
//     @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700;800&family=Poppins:wght@400;500&display=swap');
//   `}</style>
// );

const GaLotseMicrosite = () => {
  const [showModal, setShowModal] = useState(false);
  const [navOpen, setNavOpen] = useState(false)

  const ref = useRef()

  const outSideClick = () => {
    setNavOpen(false)
    setShowModal(false)
  }

  useOutsideClick(ref, () => outSideClick())

  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <>
      {/* <FontLoader /> */}
      <div className="font-poppins text-black">
        {/* Header */}
        <header className="header py-4 px-6">
          <div className="max-w-6xl mx-auto flex justify-between items-end">
            <div className="flex items-center">
              <img src="images/ga-ffm_logo.svg" alt="GA-Lotse Logo" className="h-12" />
            </div>

            <nav className="">
              <ul className="md:flex space-x-6 hidden">
                <li><a href="#hintergrund" className="headerSingles font-medium">Vision</a></li>
                <li><a href="#features" className="headerSingles font-medium">Features</a></li>
                <li><a href="#technical" className="headerSingles font-medium">Technik</a></li>
                <li><a href="#betrieb" className="headerSingles font-medium">Betrieb</a></li>
                <li><a href="#opencode" className="headerSingles font-medium">Opencode</a></li>
                <li><a href="#contact" className="headerSingles font-medium">Kontakt</a></li>
              </ul>
              <div
                ref={ref}
                className={`space-y-2 md:hidden ${navOpen ? "mb-1" : "mb-3"}`}
                onClick={() => setNavOpen((prev) => !prev)}
              >
                {!navOpen &&
                  <>
                    <span style={{ backgroundColor: "#0099D6" }} className="block h-0.5 w-8 animate-pulse "></span>
                    <span style={{ backgroundColor: "#0099D6" }} className="block h-0.5 w-8 animate-pulse "></span>
                    <span style={{ backgroundColor: "#0099D6" }} className="block h-0.5 w-8 animate-pulse "></span>
                    <div
                      className="absolute top-0 right-0 px-8 py-8"
                      onClick={() => setNavOpen(false)}
                    >
                    </div>
                  </>
                }
                {navOpen &&
                  <>
                    <svg
                      style={{ color: "#0099D6" }}
                      className="h-9 w-9 "
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="18" y1="6" x2="6" y2="18" />
                      <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                    <div className="w-full absolute md:hidden" style={{ top: '3.2rem', right: '0rem', zIndex: 99 }}>
                      <ul className="menu2 font-medium flex flex-col md:p-0 mt-4 divide-y divide-slate-200 ">
                        <li>
                          <a href="#hintergrund" className="headerSingles font-medium">
                            <div className='menu2element'>Hintergrund</div>
                          </a>
                        </li>
                        <li>
                          <a href="#features" className="headerSingles font-medium">
                            <div style={{ backgroundColor: "#e6f6ff" }} className='menu2element'>Features</div>
                          </a>
                        </li>
                        <li>
                          <a href="#technical" className="headerSingles font-medium">
                            <div className='menu2element'>Technik</div>
                          </a>
                        </li>
                        <li>
                          <a href="#betrieb" className="headerSingles font-medium">
                            <div style={{ backgroundColor: "#e6f6ff" }} className='menu2element'>Betrieb</div>
                          </a>
                        </li>
                        <li>
                          <a href="#contact" className="headerSingles font-medium">
                            <div className='menu2element'>Kontakt</div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </>
                }
              </div>
            </nav>
          </div>
        </header>


        {/* Hero Section */}
        <section className="startIMG relative h-[600px] flex items-center">
          <div
            className="absolute inset-0 bg-cover bg-center z-0"
            style={{ backgroundImage: "url('images/comprimiert/bg_gradients_horizontal_arrows.png')" }}
          ></div>
          {/* <div
            className="absolute inset-0 bg-cover bg-center z-0"
            style={{ backgroundImage: "url('images/arrows/bg_arrows_layer_a_solid.svg')" }}
          ></div> */}
          {/* <div
            className="absolute inset-0 bg-cover bg-center z-0"
            style={{ backgroundImage: "url('images/arrows/bg_arrows_layer_b_solid.svg')" }}
          ></div> */}
          {/* <div
            className="absolute inset-0 bg-cover bg-center z-0"
            style={{ backgroundImage: "url('images/arrows/bg_arrows_layer_c_solid.svg')" }}
          ></div> */}
          {/* <div
            className="absolute inset-0 bg-cover bg-center z-0"
            style={{ backgroundImage: "url('images/arrows/bg_arrows_all_solid.svg')" }}
          ></div> */}
          {/* <div
            className="absolute inset-0 bg-cover bg-center z-0"
            style={{ backgroundImage: "url('images/arrows/bg_arrows_all_blur.svg')" }}
          ></div> */}
          {/* <div
            className="absolute inset-0 bg-cover bg-center z-0"
            style={{ backgroundImage: "url('images/test.svg')" }}
          ></div> */}

          <div className="max-w-5xl mx-auto text-center relative z-10 px-6">

            <img src="images/ga-lotse_logo-bold.svg" alt="GA-Lotse Logo" className="h-40 mx-auto mb-10" />

            <p className="text-3xl mb-10 schriftaufBild font-semibold drop-shadow-lg">
              Die Einheitliche Software für Gesundheitsämter
            </p>
            <a
              href="#contact"
              className="mehrButton bg-primary-solid-Bg text-primary-solid-Color px-8 py-4 rounded-md text-xl  hover:bg-primary-soft-Color transition duration-300 shadow-lg"
            >
              Mehr erfahren
            </a>
          </div>
        </section>

        {/* Background/Context */}
        <section id='hintergrund' className="py-8 px-6 ">
          <div className=" card mx-auto" data-aos="fade-right">
            <h2 className=" md:text-3xl text-md mb-6"><span>Vision und Umsetzung</span></h2>
            {/* <p className=" mb-4"><strong>GA-Lotse ist die einheitliche Software für die Gesundheitsämter</strong>, die gerade entwickelt wird. Sie besteht aus einem Grundmodul mit gemeinsamen Funktionen und spezialisierten Modulen für die jeweiligen Abteilungen der Gesundheitsämter.
              <br />
              <br />
              Ziel ist es, nach und nach in den Gesundheitsämtern die vielen Einzellösungen und Papierarbeit zu ersetzen. Der Start ist für den 01.10.2024 geplant, beginnend mit dem Grundmodul und einigen ersten Fachmodulen.</p> */}
            <p>
              „GA-Lotse - Das Digitalisierungspaket der Zukunft“ für den Einsatz in Gesundheitsämtern folgt der Idee durch eine gemeinsame, einheitliche Software die Abläufe in den Fachabteilungen für die Mitarbeitenden zu optimieren. <br />

              Mit der Softwareentwicklung GA-Lotse entsteht eine hochgeschützte und effektive Datenverarbeitung. Sie wird die Gesundheitsämter in ganz Deutschland befähigen, für die Veränderungen in der Arbeitswelt und den Anforderungen in der Zusammenarbeit im Gesundheitswesen gut vorbereitet zu sein. <br />

              Die Abteilung „Digitale Zukunft, IT und strategische Planung“ im Gesundheitsamt Frankfurt hat die Anforderungen, die Umsetzung und die ersten Nutzertests gestaltet. Das Projekt wird von der Abteilung Digitale Zukunft, IT und strategische Planung des Gesundheitsamt Frankfurt am Main in Zusammenarbeit mit dem hessischen Ministerium für Familie, Senioren, Sport, Gesundheit und Pflege sowie mit externen Dienstleistern umgesetzt. <br />

              „Open Source“ und „Zero Trust“ stehen unter anderem als wichtige Eckpfeiler für die zukunftsorientierte Softwareentwicklung, die von möglichst vielen Menschen genutzt werden soll – in den Gesundheitsämtern wie auch durch die Bürger:innen im Rahmen eines individualisierbaren Bürgerportales. <br />

              Die Softwareentwicklung für die Digitalisierung der Gesundheitsämter wurde im Rahmen des „Pakt für den ÖGD“ entschieden und wird mit Fördermitteln der Europäischen Union finanziert.
            </p>
          </div>
        </section >


        {/* Key Features */}
        <section section id="features" className="py-8 px-6 " >
          <div className="card mx-auto" data-aos="fade-left">
            <h2 className="md:text-3xl text-md mb-6"><span>FEATURES</span></h2>
            <ul className="list-disc list-inside space-y-2">
              <li><strong>Anpassbar</strong>: Software skalierbar für kleine und große Gesundheitsämter</li>
              <li><strong>Desktop & Mobile</strong>: Arbeiten mit PC, Laptop, Tablet und Smartphone leicht möglich</li>
              <li><strong>Überall dabei</strong>: Einsatz sowohl offline als auch online</li>
              <li><strong>Grundmodul</strong>: Interne Prozesse, Chatfunktion, Statistik, Bürgerportal mit Terminmodul</li>
              <li><strong>Erste Fachmodule</strong>: Einschulungsuntersuchungen, Begehungen, Masernimpfung, Impfberatung</li>
              <li><strong>Weiterbildung</strong>: Schulungs- und Einführungsprogramme für Mitarbeiter</li>
            </ul>
          </div>
        </section >

        {/* Technical Background */}
        <section section id="technical" className="py-8 px-6 " >
          <div className="card mx-auto" data-aos="fade-right">
            <h2 className="md:text-3xl text-md mb-6"><span>TECHNIK</span></h2>
            <ul className="list-disc list-inside space-y-2">
              <li><strong>Open Source</strong></li>
              <li><strong>Agile Entwicklung</strong> der Module unter Einbeziehung der Anwender</li>
              <li><strong>Datensicherheit</strong> auf maximalem Niveau - "Zero Trust"</li>
              <li><strong>Offene Schnittstellen</strong></li>
              <li><strong>Kontinuierliche Verbesserung</strong> und Erweiterung der Module</li>
            </ul>
          </div>
        </section >

        {/* Pricing Options */}
        {/* <section section id='betrieb' className="py-8 px-6 " >
          <div className="card mx-auto" data-aos="fade-left">
            <h2 className="md:text-3xl text-md mb-6"><span>KOSTENVERTEILUNGSVERSIONEN</span></h2>
            <ul className="list-disc list-inside space-y-2">
              <li><strong>Software as a Service (SaaS)</strong></li>
              <li><strong>SaaS mit Servicehotline</strong></li>
              <li><strong>Optional mit Hosting und Servicehotline</strong></li>
            </ul>
          </div>
        </section > */}

        {/* Contact Form */}
        <section section id="opencode" className="py-8 px-6 " >
          <div className="card mx-auto" data-aos="fade-right">
            <h2 className="md:text-3xl text-md mb-6"><span>Zu GA-Lotse auf OpenCoDE</span></h2>
            <p className="mb-4">Open CoDE ist die gemeinsame Plattform der Öffentlichen Verwaltung für den Austausch von Open Source Software: <a target="_blank" rel="noreferrer" className="text-primary-plain-Color hover:underline" href='https://gitlab.opencode.de/ga-lotse'>opencode</a>. Sie sind herzlich eingeladen, sich die quelloffenen Software GA-Lotse anzuschauen.</p>
          </div>
        </section >

        {/* Contact Form */}
        <section section id="contact" className="py-8 px-6 " >
          <div className="card mx-auto" data-aos="fade-right">
            <h2 className="md:text-3xl text-md mb-6"><span>KONTAKT</span></h2>
            <p className="mb-4">Sie wollen mehr Details wissen? Kontaktieren Sie uns:</p>
            <a href="mailto:gesundheitsamt.einheitliche-software@stadt-frankfurt.de" className="text-primary-plain-Color hover:underline"><a href='mailt&#111;&#58;&#103;&#37;6&#53;sundheit&#37;7&#51;amt&#46;e&#105;nh&#101;itlic%68e&#45;&#37;73&#37;6&#70;&#102;%7&#52;ware&#64;%7&#51;&#116;%61d%7&#52;-&#102;%7&#50;a%&#54;Ek&#102;&#37;75&#37;&#55;2t&#37;2E%64&#101;'>g&#101;sundheit&#115;&#97;mt&#46;ei&#110;he&#105;t&#108;ich&#101;-&#115;o&#102;t&#119;a&#114;e&#64;sta&#100;t-frankfurt&#46;&#100;&#101;</a></a>
          </div>
        </section >

        {/* Footer */}
        < Footer setShowModal={setShowModal} />

        {/* Imprint Modal */}
        {
          showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-20">
              <div ref={ref} className="bg-white rounded-lg p-8 max-w-2xl w-full relative overflow-auto h-4/6">
                <h2 className="text-2xl mb-4">Impressum</h2>
                <p>
                  {/* <strong>Impressum Microsite GA-Lotse</strong> */}
                {/* <br />
                <br /> */}
                  Gesamtverantwortung:<br />
                  Stadt Frankfurt am Main <br />
                  DER MAGISTRAT <br />
                  Römerberg 23 <br />
                  60311 Frankfurt am Main <br />
                  Website: www.frankfurt.de <br />
                  <br />

                  USt-ID: DE 114 110 388 <br />
                  <br />
                  Verantwortung für diese Microsite: <br />
                  Stadt Frankfurt am Main <br />
                  Der Magistrat <br />
                  <br />
                  Gesundheitsamt Frankfurt am Main <br />
                  Abteilung Digitale Zukunft, IT und strategische Planung <br />
                  Breite Gasse 28 <br />
                  60313 Frankfurt am Main <br />
                  Microsite: GA-Lotse.de <br />

                  GA-Lotse ist ein Kooperationsprojekt des <br />
                  Hessischen Ministeriums für Familie, Senioren, Sport, Gesundheit und Pflege <br />
                  mit dem Gesundheitsamt Frankfurt unter der EU-Förderung NextGeneration. <br />
                  <br />
                  Telefonische Auskünfte <br />
                  Informationen erhalten Sie über die Rufnummer:  +49 (0) 800 - 4256873<br />
                  <br />
                  Kontakt bei Presseanfragen: <br />
                  gesundheitsamt_einheitliche-software@stadt-frankfurt.de<br />
                  <br />
                  Kontakt bei Fragen zur Microsite: <br />
                  eMail: gesundheitsamt.einheitliche-software@stadt-frankfurt.de <br />
                  Die Abteilung Digitale Zukunft, IT und strategische Planung des Gesundheitsamtes der Stadt Frankfurt am Main zeichnet für ihre Inhalte auf www.ga-lotse.de redaktionell verantwortlich.<br />
                  <br />
                  Verantwortung:<br />
                  Stefanie Kaulich, Abteilungsleitung Digitale Zukunft, IT und strategische Planung.<br />
                  Bei Fragen oder Anregungen zu konkreten Inhalten und Seiten können Sie sich gerne an Frau Kaulich oder die unter „Kontakt“ benannte eMail wenden.<br />

                  Technische Realisierung:<br />
                  Gesundheitsamt der Stadt Frankfurt am Main<br />
                  Abteilung Digitale Zukunft, IT und strategische Planung<br />
                  Breite Gasse 28 <br />
                  60313 Frankfurt am Main <br />
                  <br />
                  Bei Fragen oder Anmerkungen: <br />
                  gesundheitsamt.einheitliche-software@stadt-frankfurt.de <br />
                  Microsites: <br />
                  Microsites sind Informationsangebote, die als eigenständige und in sich abgeschlossene Webseiten anmuten. Microsites sind innerhalb des Onlineauftrittes von frankfurt.de realisiert und auf Basis der gleichen technischen Infrastruktur umgesetzt. <br />
                  <br />
                  Hinweise zum Datenschutz: <br />
                  Informationen zum Datenschutz finden Sie unter <a target="_blank" rel="noreferrer" href='https://frankfurt.de/datenschutz'  className="text-primary-plain-Color hover:underline">https://frankfurt.de/datenschutz</a> <br />
                </p>
                <button onClick={() => setShowModal(false)} className="absolute top-4 right-4 text-neutral-700 hover:text-danger-outlined-Color">
                  <XCircle size={24} />
                </button>
              </div>
            </div>
          )
        }
      </div >
    </>
  );
};

export default GaLotseMicrosite;